// <!-- API -->
import { ref, computed } from 'vue';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { DateTimeISO } from '@/utils/datetime';
import { getFileType } from '@/utils/file';

// <!-- COMPOSABLES -->
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state';

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
/** @typedef {import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource} DatasetBatchResource */

/**
 * Get the file preview grid.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Store} store
 * @param {Object} payload
 * @param {V.Ref<Map<String, UploadRecord>>} payload.records
 */
// ts-ignore
export function useUploadSummaryGrid({ emit }, { commit }, { records }) {
    /** Get grid API. */
    // ts-ignore
    const { defaultColDef, ...grid } = useAgGridVue();

    /**
     * Selected location row data.
     * @type {V.ComputedRef<({ name: String, type: String, size: Number, location: LocationResource, batch: DatasetBatchResource, profile?: Boolean, uploaded?: Boolean, ingested?: Boolean })[]>}
     */
    const selectedRecords = computed(() => {
        return [...records.value.values()].map((r) => {
            const { filename, file, location, mappingProfile, batch } = r;
            return {
                name: filename,
                type: file.exists ? file.value.type : '',
                size: file.exists ? file.value.size : 0,
                location: location.exists ? location.value : null,
                profile: mappingProfile.exists,
                batch: batch.exists ? batch.value : null,
                uploaded: r.isDatasetBatchUploaded,
                ingested: r.isDatasetBatchIngested,
            };
        });
    });

    /** References. */
    const state = {
        defaultColDef: ref(defaultColDef),
        domLayout: ref('autoHeight'),
        /** @type {V.Ref<AgGrid.ColumnDef[]>} */
        columnDefs: ref([]),
        rowSelection: ref('multiple'),
        rowData: computed(() =>
            actions.mapRecordsToRowData(selectedRecords.value)
        ),
        rowHeight: ref(50),
        /** @type {V.Ref<GridApi>} */
        gridApi: ref(null),
        /** @type {V.Ref<ColumnApi>} */
        gridColumnApi: ref(null),
    };

    // HOOKS
    const useDomLayout = (layout) => {
        state.domLayout.value = layout;
    };

    const useDefaultColDef = (defaultDefs) => {
        state.defaultColDef.value = defaultDefs;
    };

    const useColumnDefs = (colDefs) => {
        state.columnDefs.value = colDefs;
    };

    const useGridApi = (api) => {
        state.gridApi.value = api;
    };

    const useGridColumnApi = (api) => {
        state.gridColumnApi.value = api;
    };

    const useRowHeight = (height) => {
        state.rowHeight.value = height;
    };

    /**
     * Format location date string.
     * @param {String} dateString
     * @returns
     */
    const useDateFormat = (dateString) => {
        if (!isNil(dateString) && dateString !== '') {
            const date = DateTimeISO.parse(dateString);
            return date.toLocaleDateString();
        } else {
            return '-';
        }
    };

    // GETTERS
    const getters = {
        // Allows us to reorganize easily.
        /** @returns {Record<String, AgGrid.ColumnDef>} */
        getUploadSummaryColumnDefs: () => ({
            index: {
                headerName: '',
                field: 'index',
                width: 35,
                maxWidth: 100,
                pinned: true,
                sortable: true,
                resizable: false,
            },
            name: {
                headerName: 'File Name',
                field: 'name',
                minWidth: 150,
                cellClass: 'h-full pt-0.5 items-center align-middle',
                pinned: true,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
            },
            type: {
                headerName: 'File Type',
                field: 'type',
                valueGetter: getFileType,
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            size: {
                headerName: 'File Size',
                field: 'size',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            location: {
                headerName: 'Location',
                field: 'location',
                minWidth: 450,
                cellClass: 'h-full pt-0.5 items-center align-middle',
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            profile: {
                headerName: 'Has Profile',
                field: 'profile',
                minWidth: 80,
                width: 80,
                cellClass: 'h-full pt-0.5 items-center align-middle',
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            ingested: {
                headerName: 'Ingested?',
                field: 'ingested',
                minWidth: 80,
                width: 80,
                cellClass: 'h-full pt-0.5 items-center align-middle',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
            },
            start: {
                headerName: 'Data Start',
                field: 'start', // # TODO change this value to "data_start" when backend route provides the value
                valueFormatter: (params) => {
                    const date = params.value;
                    return useDateFormat(date);
                },
                filter: false,
                floatingFilter: false,
                minWidth: 120,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            end: {
                headerName: 'Data End',
                field: 'end', // # TODO change this value to "data_end" when backend route provides the value
                valueFormatter: (params) => {
                    const date = params.value;
                    return useDateFormat(date);
                },
                filter: false,
                floatingFilter: false,
                minWidth: 120,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            points: {
                headerName: '# of Data Points',
                field: 'points',
                filter: false,
                floatingFilter: false,
                valueFormatter: (params) => {
                    const points = params.value;
                    return points ?? 'No Data';
                },
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
        }),
        getRowIdFromFileName: (data) => data.name,
    };

    // ACTIONS
    const actions = {
        /**
         * @param {selectedRecords['value']} records
         */
        mapRecordsToRowData: (records) => {
            return records.map((record, index) => ({
                index,
                ...record,
                location: record?.location?.label,
                start: record.batch.dateDataStart,
                end: record.batch.dateDataEnd,
                points: record.batch.points,
                profile: record.profile ? 'Yes' : 'No',
                ingested: record.ingested ? 'Yes' : 'No',
            }));
        },
        updateColumnDefs: () => {
            const columnDefs = getters.getUploadSummaryColumnDefs();
            useColumnDefs([
                columnDefs.name,
                columnDefs.location,
                columnDefs.start,
                columnDefs.end,
                columnDefs.points,
                columnDefs.ingested,
            ]);
        },
        onGridReady: (event) => {
            state.gridApi.value = event.api;
            state.gridColumnApi.value = event.columnApi;
        },
        onColumnResized: (event) => {
            // See: grid.onColumnResized(event);
            state.gridApi.value?.refreshCells();
            emit('resize:grid', event); // Currently unused.
        },
        // ts-ignore
        onRowDataChanged: (event) => {
            // state.gridApi.value?.sizeColumnsToFit();
            state.gridApi.value?.refreshCells();
            // state.gridColumnApi.value?.autoSizeAllColumns(false);
        },
        // ts-ignore
        onChangeRowData: (event) => {
            state.gridApi.value?.refreshCells();
        },
    };

    return {
        useGridApi,
        useGridColumnApi,
        useDefaultColDef,
        useColumnDefs,
        useDomLayout,
        useRowHeight,
        state,
        getters,
        actions,
    };
}

export default useUploadSummaryGrid;
